<template>
  <div class="dashboard">
    <Loader v-if="performingRequest" />
    <div class="dashboard__container">


      <div class="dashboard__container--header flex flex-column pt-5" style="text-align: center;" v-if="userProfile && userProfile.id">
        <h1>Welcome {{userProfile.firstName}} {{userProfile.lastName}}</h1>
        <h2 v-if="eventInfo && eventInfo.title">{{eventInfo.title}}</h2>
        <h3>Employee Check In and Out</h3>
        <!-- <h2 v-if="venueCheckInEvent">{{venueCheckInEvent.title}}</h2> -->
        <p class="pt-2" v-if="!showCheckedIn && (!dayAssignment || !dayAssignment.inTime || !dayAssignment.outTime)">Current Time: {{ new Date() | moment("hh:mm a") }}</p>
      </div>

      <div class="dashboard__container--body justify-center flex-column pt-5 mb-5 pb-5" style="justify-content: center; text-align: center;" v-if="userProfile && !userProfile.id">
        <h3>You must be logged in to clock-in and clock-out.</h3>
        <p>Please log in and then scan the QR code to try again.</p>
        <div class="mt-5">
         <button @click="login()" class="btn btn__primary">Login / Signup</button>
         </div>
      </div>


      <div class="dashboard__container--body justify-center flex-column  mb-5 pb-5" style="justify-content: center; text-align: center;" v-if="userProfile && userProfile.id">

        <!-- <div class="mt-2 flex flex-column" v-if="(eventUsers && eventUsers.length >= 1) && !showCheckedIn && (userProfile && !userProfile.id)">
          <label for="select">Find Your Name:</label>
          <select
            class="checkInSelect mt-2"
            placeholder="find your name"
            v-model="activeUser"
            @change="findAssignments()"
            >
            <option v-for="option in users" v-bind:value="option">
              {{ option.firstName }} {{ option.lastName }} / {{ option.position }}
            </option>
          </select>
        </div> -->
        <!-- <div v-if="showEarly" class="mt-5">
          <p class="danger bold">It's too early. Your shift doesn't start until {{activeUser.startTime}}. You can come back and check-in 10 minutes before your shift.</p>
        </div> -->


        <div class="mt-0" v-if="activeUser && !showCheckedIn && (!dayAssignment || (dayAssignment && !dayAssignment.inTime))">
          <button class="btn btn__primary btn__mega ma-3" @click="checkIn()">
            Check In
            <transition name="fade">
              <span class="ml-2" v-if="performingRequest2">
              <i class="fa fa-spinner fa-spin"></i>
              </span>
            </transition>
          </button>
        </div>

        <!-- <div class="mt-5" v-if="(userProfile && userProfile.id) && !showCheckedIn && (!dayAssignment || (dayAssignment && !dayAssignment.inTime))">

          <button class="btn btn__primary btn__mega ma-3" @click="checkIn()">
            Check In
            <transition name="fade">
              <span class="ml-2" v-if="performingRequest">
              <i class="fa fa-spinner fa-spin"></i>
              </span>
            </transition>
          </button>
        </div>
  -->
        

        <div v-if="dayAssignment && !showCheckedIn"> 
          <p v-if="dayAssignment.inTime">Checked-In: {{formatAMPM(dayAssignment.inTime) | moment("hh:mm a")}}</p>
          <p v-if="dayAssignment.outTime">Checked-Out: {{formatAMPM(dayAssignment.outTime) | moment("hh:mm a")}}</p>
          <p v-if="dayAssignment.breakTime && dayAssignment.breakTime != 0">Break Time: {{formatBreak(dayAssignment.breakTime) | moment('HH [Hours] mm [Minutes]')}}</p>
        </div>


          <div class="mt-3" v-if="activeUser && dayAssignment && !showCheckedIn && dayAssignment.inTime && !dayAssignment.outTime && !showBreak">
            <button class="btn btn__primary btn__mega ma-3" @click="checkOut()">
              Check Out
              <transition name="fade">
                <span class="ml-2" v-if="performingRequest2">
                <i class="fa fa-spinner fa-spin"></i>
                </span>
              </transition>
            </button>
          </div>

          <transition name="fade">
            <div class="mt-0" v-if="showBreak" style="margin:auto; max-width:360px;">
              <div class="strong">Did you take a 30-minute lunch break?</div>
              <div class="flex justify-space-between">
                <button class="btn btn__primary btn__mega ma-3" @click="yesBreak()">
                  Yes
                  <transition name="fade">
                    <span class="ml-2" v-if="performingRequest3">
                    <i class="fa fa-spinner fa-spin"></i>
                    </span>
                  </transition>
                </button>
                <button class="btn btn__primary btn__mega ma-3" @click="noBreak()">
                  No
                  <transition name="fade">
                    <span class="ml-2" v-if="performingRequest4">
                    <i class="fa fa-spinner fa-spin"></i>
                    </span>
                  </transition>
                </button>
              </div>
            </div>
          </transition>


          <div class="mt-3" v-if="showCheckedIn || (dayAssignment && dayAssignment.inTime && dayAssignment.outTime)">
            <h3>All Set. Thanks!</h3>
            <p>If you need assistance, please reach out to your hiring manager. Thank you!</p>
          </div>

      </div>
    </div>
  </div>
</template>

<style scoped type="text/css">
  select {
    width: 300px;
    margin: auto;
    background: white;
    color: black;
  }
  ::placeholder {
    color: black !important;
    opacity:1;
  }
</style>

<script>
import { mapState } from 'vuex'
import * as moment from 'moment'
import router from '@/router'
import Loader from '@/components/Loader.vue'
const fb = require('../firebaseConfig.js')
import firebase from 'firebase/app';

export default {
  name: 'checkInOut',
  data: () => ({
    performingRequest: false,
    performingRequest2: false,
    performingRequest3: false,
    performingRequest4: false,
    activeUser: '',
    showCheckedIn: false,
    showEarly: false,
    showBreak: false
  }),
  components: {
    Loader,
  },
  created () {
    // this.$store.dispatch("getVenueFromCheckin", this.$route.params.id);
    // this.$store.dispatch("getVenueCheckinFromAssignment", this.$route.params.id);
    // this.$store.dispatch("getStaffFromAssignment", this.$route.params.id);
    this.$store.dispatch('getEventFromIdAss', this.$route.params.id);
  },
  watch: {
    userProfile: {
      handler(newProfile, oldProfile) {
        if (newProfile && newProfile.id) {
          this.activeUser = newProfile;
          this.findAssignmentsFromUser();
        } else {
          // this.$store.dispatch("getStaffFromAssignment", this.$route.params.id);
        }
      },
      immediate: true, // Trigger the handler immediately when the component is mounted
    },
  },
  // mounted () {
  //   if (
  //     this.userProfile &&
  //     this.userProfile.id
  //   ) {
  //     this.activeUser = this.userProfile
  //     this.findAssignmentsFromUser()
  //   } else {
  //     this.$store.dispatch("getStaffFromAssignment", this.$route.params.id);
  //   }
  //   this.$store.dispatch('getEventFromIdAss', this.$route.params.id)
  // },
  computed: {
    ...mapState(['dayAssignment', 'eventUsers', 'userProfile', 'eventInfo']),
    users() {
      function compare(a, b) {
        if (a.lastName < b.lastName)
          return -1;
        if (a.lastName > b.lastName)
          return 1;
        return 0;
      }
      return this.eventUsers.sort(compare);
    },
    // canCheckIn() {
    //   if (this.activeUser) {

    //   }
    // }
  },
  methods: {
    login() {
      let url = `/auth/login`
      router.push(url)
    },
    formatBreak: function(value) {
      // Assuming value is in hours
      const hours = Math.floor(value);
      const minutes = Math.round((value - hours) * 60);

      let formattedDuration = '';

      if (hours > 0) {
        formattedDuration += `${hours} Hours `;
      }

      if (minutes > 0) {
        formattedDuration += `${minutes} Minutes`;
      }

      return formattedDuration.trim();
    },
    formatAMPM(date) {
      if (typeof date === "string") {
        const [hours, minutes] = date.split(":");
        const parsedHours = Number(hours);
        const parsedMinutes = Number(minutes);
        let ampm = "AM";

        if (parsedHours >= 12) {
          ampm = "PM";
        }

        const formattedHours = parsedHours % 12 || 12;
        const formattedMinutes = parsedMinutes < 10 ? `0${parsedMinutes}` : parsedMinutes;

        return `${formattedHours}:${formattedMinutes} ${ampm}`;
      } else if (date instanceof Date) {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? "PM" : "AM";
        const formattedHours = hours % 12 || 12;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        return `${formattedHours}:${formattedMinutes} ${ampm}`;
      }

      return date;
    },
    async findAssignments() {
      await this.$store.dispatch('clearDayAssignment')
      console.log('findAssignments')
      const formattedDate = moment().format('YYYY-MM-DD');
      console.log(this.activeUser.userId)
      if (this.activeUser) {
        await this.$store.dispatch('getDayAssignment', {
          user: this.activeUser.userId,
          event: this.$route.params.id,
          day: formattedDate,
        });
      } else {
        await null
      }
    },
    async findAssignmentsFromUser() {
      await this.$store.dispatch('clearDayAssignment')
      console.log('findAssignmentsFromUser')
      const formattedDate = moment().format('YYYY-MM-DD');
       if (this.userProfile && this.userProfile.id) {
        await this.$store.dispatch('getDayAssignment', {
          user: this.userProfile.id,
          event: this.$route.params.id,
          day: formattedDate,
        });
      } else {
        await null
      }
      
    },
    async yesBreak() {
      this.performingRequest3 = true;
      // Dispatch the check-in action to the store
      await this.$store.dispatch('yesBreakFromAssignment', {
        assignment: this.dayAssignment.id,
        breakTime: 0.5,
      });
      await this.$store.dispatch('updateAssignmentTotal', this.dayAssignment)
      this.showCheckedIn = true
      this.showBreak = false
      this.performingRequest3 = false;
    },
    async noBreak() {
      // Dispatch the check-in action to the store
      this.performingRequest4 = true;
      await this.$store.dispatch('noBreakFromAssignment', {
        assignment: this.dayAssignment.id,
        breakTime: 0,
      });
      await this.$store.dispatch('updateAssignmentTotal', this.dayAssignment)
      this.showCheckedIn = true
      this.showBreak = false
      this.performingRequest4 = false;
    },
    async checkOut() {
      // Get the current time formatted as 'hh:mm A'
      const currentTime = moment().format('HH:mm');
      console.log(currentTime);

      // Dispatch the check-in action to the store
      await this.$store.dispatch('checkOutFromAssignment', {
        assignment: this.dayAssignment.id,
        out: currentTime,
      });
      this.showBreak = true
    },
    async checkIn() {
      this.performingRequest2 = true;

      const formattedDate = moment().format('YYYY-MM-DD');
      const currentTime = moment().format('HH:mm');
      console.log(currentTime)
      console.log(formattedDate)

      if (!this.dayAssignment) {

        const lockNewRows = firebase.functions().httpsCallable('lockNewRows');

        const job = {
          rate: this.activeUser.regRate || 0,
          tipped: this.activeUser.tipped || false,
          title: this.activeUser.position || "",
        }

        let activeU = this.activeUser
        activeU.userId = this.activeUser.id
        
        await lockNewRows({
          event: this.eventInfo,
          day: formattedDate,
          row: activeU,
          job: job,
          sendPlacementNotifications: false,
          inTime: currentTime
        });
      } else {
        await this.$store.dispatch('checkInFromAssignment', {
          assignment: this.dayAssignment.id,
          in: currentTime,
        });
      }
      this.performingRequest2 = false;
      this.showCheckedIn = true
    },
  },
  beforeDestroy () {
    this.$store.dispatch('clearEventUsers')
    this.$store.dispatch('clearDayAssignment')
    
  }
}

</script>
